$light-gray: #f2f3f8;
$black: #262626;
$dark-mode: #303030;
$dark-mode-lighten: #424242;
$light-mode: rgba(255, 255, 255, 0.87);
$purple: #994fe3;
$white: #ffffff;
$gray: #828282;
$light-gray-alpha: #97989b;
$light-gray-beta: #dadada;
$yellow: #fecd6c;
$error: #721c24;
$warning: #856404;
$success: #155724;
$purple-background: rgba(189, 0, 255, 0.05);

// custom colors
// https://htmlcolorcodes.com/colors/shades-of-green/
$grass-green: #7cfc00;

$font-size: 14px;

@function purpleBackground($opacity) {
  @return rgba(189, 0, 255, $opacity);
}

@function redBackground($opacity) {
  @return rgba(114, 28, 36, $opacity);
}

@function greenBackground($opacity) {
  @return rgba(21, 87, 36, $opacity);
}

@function orangeBackground($opacity) {
  @return rgba(133, 100, 4, $opacity);
}

@function greyBackground($opacity) {
  @return rgba(128, 128, 128, $opacity);
}