@use "@angular/material" as mat;
@include mat.core();

/* For use in src/lib/core/theming/_palette.scss */
$md-nccalc-primary: (
  50: #f3eafc,
  100: #e0caf7,
  200: #cca7f1,
  300: #b884eb,
  400: #a869e7,
  500: #994fe3,
  600: #9148e0,
  700: #863fdc,
  800: #7c36d8,
  900: #6b26d0,
  A100: #ffffff,
  A200: #e8daff,
  A400: #c8a7ff,
  A700: #b88dff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-nccalc-accent: (
  50: #fff9ed,
  100: #fff0d3,
  200: #ffe6b6,
  300: #fedc98,
  400: #fed582,
  500: #fecd6c,
  600: #fec864,
  700: #fec159,
  800: #feba4f,
  900: #fdae3d,
  A100: #ffffff,
  A200: #ffffff,
  A400: #fff3e4,
  A700: #ffe8ca,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$my-primary: mat.define-palette($md-nccalc-primary);
$my-accent: mat.define-palette($md-nccalc-accent);
$my-warn: mat.define-palette(mat.$red-palette);

$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
  )
);

$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
  )
);
@include mat.core-theme($light-theme);
@include mat.all-component-themes($light-theme);

body.dark-mode {
  @include mat.all-component-colors($dark-theme);
}
