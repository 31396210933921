/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";
// ─────────────────────────────────────────────────────────────────────────────
// Mixins
@import "./assets/styles/mixins/separator.mixin";
@import "./assets/styles/mixins/dimension.mixin";
@import "./assets/styles/mixins/spacing.mixin";
// ─────────────────────────────────────────────────────────────────────────────
// Loading
@import "./assets/styles/loading";
// ─────────────────────────────────────────────────────────────────────────────
// Custom Scroll
@import "./assets/styles/custom-scroll";
// ─────────────────────────────────────────────────────────────────────────────
// Shared Vars
@import "./assets/styles/vars";
// ─────────────────────────────────────────────────────────────────────────────
// Code mirror
// fold
@import "~codemirror/addon/fold/foldgutter.css";
// hint
@import "~codemirror/addon/hint/show-hint.css";
// lint
@import "~codemirror/addon/lint/lint.css";
// dialog
@import "~codemirror/addon/dialog/dialog.css";
// search
@import "~codemirror/addon/search/matchesonscrollbar.css";
// fullscreen
@import "./assets/styles/parts/codemirror-fullscreen";
// Codemirror additional styles
@import "~codemirror/lib/codemirror.css";
// Codemirror theme
// light
@import "~codemirror/theme/eclipse.css";
// dark
@import "~codemirror/theme/monokai.css";

// ─────────────────────────────────────────────────────────────────────────────
// Palette
@import "./assets/styles/theme/theme";
// ─────────────────────────────────────────────────────────────────────────────
// Katex Support
@import "~katex/dist/katex.min.css";
// ─────────────────────────────────────────────────────────────────────────────
// Material update
@import "./assets/styles/material-update";
// ─────────────────────────────────────────────────────────────────────────────
// Dark theme
@import "./assets/styles/theme/dark.theme";
// ─────────────────────────────────────────────────────────────────────────────
// Imports the standard PrismJS theme
@import "~prismjs/themes/prism-dark.css";
// ─────────────────────────────────────────────────────────────────────────────
// Table
@import "./assets/styles/table";
// ─────────────────────────────────────────────────────────────────────────────
// Video Player Styles
@import "~video.js/dist/video-js.min.css";

/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@include generate(ml, margin-left);
@include generate(mr, margin-right);
@include generate(pl, padding-left);
@include generate(pr, padding-right);

pre {
  word-break: break-all;
  white-space: pre-wrap;
}

pre {
  font-weight: 400;

  .number-line {
    color: darkgray;
  }

  .string {
    color: darkgreen;
  }

  .number {
    color: darkorange;
  }

  .boolean {
    color: blue;
  }

  .null {
    color: grey;
  }

  .key {
    color: black;
  }
}

.w100 {
  width: 100% !important;
}

.no-border {
  border: none !important;
}

.common-container {
  position: relative;
  margin: 0 auto;
  width: 90%;
}

.dev_highlight {
  outline: 1px solid red;
  width: 100%;
  height: 100%;
}

// Full height - header height + footer height;
main {
  height: calc(100vh - 90px);
  overflow-y: auto;
  overflow-x: hidden;
}

// Static pages
.offline-page,
.internal-server-error,
.access-denied,
.page-not-found,
.not-authenticated {
  text-align: center;
  height: calc(100vh - 128px);
}

.progressbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.error-message {
  color: rgba(255, 255, 255, 1);
  background: #a01126;
}

.CodeMirror {
  border: 1px solid $purple;
}

.no-data {
  text-align: center;
  padding: 10px;
  width: 100%;
  font-size: 16px;
}

.separator {
  &-global-variables {
    &:after {
      @include separator(#cdcdcd, 46px);
    }
  }
}

.border-left {
  border-left: 1px solid #cdcdcd;
}

.preview-image {
  max-height: 500px;
}

.CodeMirror {
  resize: both;
  &-wrap {
    max-width: 100%;
  }
  &-gutter-wrapper {
    // fix for jumping line numbers in codemirror
    left: -60px !important;
  }
}

.fullscreen .CodeMirror-wrap {
  width: 100%;
  height: calc(100vh - 180px);
}

file-upload {
  background-color: transparent !important;
  min-height: 250px !important;
}

body.dark-mode {
  .remove-btn > svg {
    fill: #fff;
  }

  .file-info,
  .remove-btn,
  .file-name {
    color: #fff;
  }
}

.profile-menu {
  padding: 20px;
  width: 330px;
  border-radius: 0 !important;

  &-section {
    h5 {
      margin-left: 10px;
      color: #9a9ba4;
      font-size: 14px;
      margin-bottom: 0;
    }

    border-bottom: 1px solid #9a9ba4;
    margin-bottom: 10px;
  }
}

.highlight {
  background-color: yellow;
  font-weight: bold;
  color: black;
}

.CodeMirror-lines > div {
  margin-left: 10px;
}

.gui-structure.gui-generic * {
  background-color: $light-gray;
  color: $dark-mode;
}

.gui-structure.gui-generic .gui-structure-header .gui-header {
  background-color: $light-gray;
  color: $dark-mode;
}

// for small icon button in a tool-bar style configuration
.small-icon-button {
  width: 24px !important;
  height: 24px !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  border: none !important;
  background-color: transparent !important;

  & > *[role="img"] {
    width: 16px;
    height: 16px;
    font-size: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .mat-mdc-button-touch-target {
    width: 24px !important;
    height: 24px !important;
  }
}

.icon-button {
  border: none !important;
  background-color: transparent !important;
}

// for the DefaultImageDirective
.def-img-skeleton {
  border-radius: 10px;
  background-color: rgb(224, 224, 224);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    border-radius: 2px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    border-radius: 10px;
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    border-radius: 2px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
