@import "../styles/vars";

// ─────────────────────────────────────────────────────────────────────────────
// Material style updates
.mat-icon {
  &.small {
    font-size: 24px;
  }
}

.mdc-icon-button {
  &.small {
    .mat-mdc-button-touch-target {
      @include dimension(24px);
    }

    @include dimension(24px);
    padding: 0;

    .mat-icon {
      @include dimension(24px);
      font-size: 24px;
    }
  }

  &.medium {
    .mat-mdc-button-touch-target {
      @include dimension(36px);
    }

    @include dimension(36px);
    padding: 0;

    .mat-icon {
      @include dimension(36px);
      font-size: 36px;
    }
  }
}

.no-wrapper {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.close {
  &.mdc-icon-button {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}

.menu-button {
  background-color: transparent;

  mat-icon {
    color: $black;
  }
}

span.mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pointer-table {
  .mat-mdc-row {
    cursor: pointer;
  }
}

.mat-mdc-raised-button.mat-primary,
.mat-mdc-unelevated-button.mat-primary.add-project-button {
  background-color: $purple;
  color: $white;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  width: 238px;
  height: 50px;
  border-radius: 40px;
}

.search-input {
  .mat-mdc-text-field-wrapper {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.white-button {

  .mdc-button__label,
  .mat-mdc-outlined-button:not(:disabled) {
    color: $white;
  }
}

.app-module-list {
  .mdc-list-item:hover .mdc-list-item__primary-text {
    color: $white;
  }

  .mdc-list-item__primary-text {
    color: $white;
    font-size: $font-size;
  }

  .mat-mdc-list-item-unscoped-content.mdc-list-item__primary-text {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -5px;
}

.cdk-drag,
app-chunk-item {
  touch-action: none;
}

body.light-mode {
  .app-module-list {
    .mdc-list-item {
      color: $dark-mode;

      &:hover {
        background-color: $light-gray;
      }
    }

    .mdc-list-item__primary-text {
      color: $dark-mode;
    }
  }
}