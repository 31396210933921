/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #aaaaaa #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 11px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  border-radius: 6px;
  border: 3px solid #ffffff;
}

*::-webkit-scrollbar:horizontal {
  height: 11px;
}
*::-webkit-scrollbar-thumb:horizontal {
  background: #aaaaaa;
  border-radius: 6px;
}
