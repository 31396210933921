@import "../vars";

#enlighten.dark-mode {
  color: $light-mode;
  background-color: $dark-mode;

  .side-navigation {
    background-color: $dark-mode-lighten;
  }

  .main-toolbar-wrapper,
  .global-var-wrapper {
    background-color: $dark-mode;
  }

  .global-var-wrapper {
    border-bottom: 1px solid $light-mode;
  }

  .chunk-item-wrapper {
    background-color: $dark-mode;
  }

  .menu-button {
    mat-icon {
      color: $light-mode;
    }
  }

  .list-group-item {
    color: $light-mode;
    background-color: $dark-mode;
  }

  .footer {
    border-top: 1px solid $light-mode;
    background-color: $dark-mode;
    p {
      color: $light-mode !important;
    }
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: $dark-mode $dark-mode-lighten;
  }

  *::-webkit-scrollbar-track {
    background: $dark-mode;
  }

  *::-webkit-scrollbar-thumb {
    background-color: $dark-mode-lighten;
    border-color: $dark-mode;
  }

  .left-side-panel-wrapper,
  .right-side-panel-wrapper {
    background-color: $dark-mode-lighten;
    color: $light-mode;
  }

  .left-side-panel-wrapper {
    border-right-color: $dark-mode;
  }

  .right-side-panel-wrapper {
    border-left-color: $dark-mode;
  }

  .panel-header {
    color: $white;
  }

  .manage-files {
    &-header {
      .mat-icon {
        color: $light-gray;
      }
      p {
        color: $light-gray;
      }
    }
  }

  .notebook-file-item {
    .mat-icon {
      color: $light-gray;
    }
    p {
      color: $light-gray;
    }
  }

  .manage-vars {
    &-header {
      .mat-icon {
        color: $light-gray;
      }
      p {
        color: $light-gray;
      }
    }
  }

  .manage-snapshots {
    &-header {
      .mat-icon {
        color: $light-gray;
      }

      p {
        color: $light-gray;
      }
    }
  }

  .snapshot-list {
    .mat-icon {
      color: $light-gray;
    }
    p {
      color: $light-gray;
    }
  }

  .manage-notebooks {
    &-header {
      .mat-icon {
        color: $light-gray;
      }

      p {
        color: $light-gray;
      }
    }
  }

  .manage-connections {
    &-header {
      .mat-icon {
        color: $light-gray;
      }

      p {
        color: $light-gray;
      }
    }
  }

  .list-group-item {
    color: white;
  }

  .mat-tree-node {
    color: $white;
  }

  .manage-versions {
    &-header {
      .mat-icon {
        color: $light-gray;
      }

      p {
        color: $light-gray;
      }
    }
  }

  .manage-blocks {
    &-header {
      .mat-icon {
        color: $light-gray;
      }
      h2 {
        color: $light-gray;
      }
    }
    &-runners {
      color: $light-gray;
    }
    &-actions {
      color: $light-gray;
    }
  }

  .active {
    outline: 1px solid $yellow;
  }

  .manage-blocks-runner-item__placeholder {
    background: $light-gray;
    border: dotted 2px $light-gray;
  }

  .gui-structure.gui-generic * {
    background-color: $dark-mode;
    color: $light-gray;
  }

  .gui-structure.gui-generic .gui-structure-header .gui-header {
    background-color: $dark-mode;
    color: $light-gray;
  }

  // New Image Gallery styles
  .image-gallery {
    .upload-section {
      border-color: $dark-mode-lighten;

      mat-icon {
        color: $light-mode;
      }
    }

    .thumbnail-container {
      background-color: $dark-mode;

      .placeholder {
        background-color: $dark-mode-lighten;

        mat-icon {
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }

    .image-card {
      background-color: $dark-mode;

      &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      }
    }

    .image-name {
      color: $light-mode;
    }

    [class*="image-size"],
    [class*="session-id"] {
      color: rgba(255, 255, 255, 0.6) !important;
    }

    .empty-state {
      background: $dark-mode;
      color: $light-mode;

      mat-icon {
        color: rgba(255, 255, 255, 0.5);
      }

      h3 {
        color: $light-mode;
      }

      p {
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .delete-button {
      background: rgba(0, 0, 0, 0.7);
      color: $light-mode;

      &:hover {
        background: rgba(0, 0, 0, 0.9);
      }
    }
  }

  // New Annotation Tool styles
  .annotation-tool {
    mat-toolbar {
      background-color: $dark-mode;
      color: $light-mode;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    .canvas-container {
      #container {
        border-color: $dark-mode-lighten;
        background-color: $dark-mode;
      }

      .loading-overlay {
        background-color: rgba(0, 0, 0, 0.9);
      }

      .status-message {
        background-color: rgba(0, 0, 0, 0.8);
        color: $light-mode;
      }
    }
  }

  // Label List styles
  .label-list-container {
    background: $dark-mode;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    .annotation-list {
      .coordinates {
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .header {
      border-bottom-color: $dark-mode-lighten;
      background-color: $dark-mode;

      .legend {
        .legend-item {
          color: rgba(255, 255, 255, 0.7);

          mat-icon {
            &.positive-point {
              color: #81c784 !important; // Lighter green for dark theme
            }

            &.negative-point {
              color: #e57373 !important; // Lighter red for dark theme
            }

            &.polygon {
              color: #64b5f6 !important; // Lighter blue for dark theme
            }
          }
        }
      }
    }
  }

  // Mask Gallery styles
  .mask-gallery {
    background: $dark-mode;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);

    .gallery-header {
      h3 {
        color: $light-mode;
      }
    }

    .masks-container {
      &::-webkit-scrollbar-track {
        background: $dark-mode-lighten;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.3);
      }
    }

    .mask-item {
      background-color: $dark-mode-lighten;

      &.selected {
        border-color: #64b5f6; // Lighter blue for dark theme, matching other components
      }

      &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      }
    }

    .mask-image-container {
      background-color: $dark-mode;

      img {
        opacity: 0.9; // Slightly reduce image brightness in dark mode
      }
    }

    .mask-score {
      background: rgba(0, 0, 0, 0.8);
      color: $light-mode;
    }

    .delete-button {
      background: rgba(0, 0, 0, 0.7);
      color: $light-mode;

      &:hover {
        background: rgba(244, 67, 54, 0.9); // Darker red for dark theme
      }
    }
  }

  .app-module-list .mdc-list-item__primary-text {
    color: $light-mode;
  }

  // Files component dark mode styles
  .files {
    &-wrapper {
      color: $light-mode;
    }

    &-header {
      .mat-icon {
        color: $light-gray;
      }
      h2 {
        color: $light-gray;
      }
    }

    .upload-zone {
      ::ng-deep .file-upload {
        border-color: rgba($light-mode, 0.2);
        background-color: rgba($dark-mode-lighten, 0.15);

        &:hover,
        &.has-files {
          border-color: rgba($light-mode, 0.4);
          background-color: rgba($dark-mode-lighten, 0.25);
        }
      }

      .upload-zone-content {
        color: rgba($light-mode, 0.8);

        mat-icon {
          color: rgba($light-mode, 0.6);
        }

        p {
          color: rgba($light-mode, 0.8);
        }

        small {
          color: rgba($light-mode, 0.6);
        }
      }

      .file-info {
        background-color: rgba($dark-mode-lighten, 0.15);

        &:hover {
          background-color: rgba($dark-mode-lighten, 0.25);
        }

        .file-name {
          color: rgba($light-mode, 0.8);
        }
      }
    }

    .upload-progress {
      background-color: $dark-mode-lighten;

      .progress-status {
        .progress-text {
          color: $light-mode;
        }
      }

      .upload-results {
        .success-message {
          color: #98fb98; // Light green for dark mode
          mat-icon {
            color: #98fb98;
          }
        }

        .error-message {
          color: #ff6b6b; // Light red for dark mode
          mat-icon {
            color: #ff6b6b;
          }
        }
      }
    }
  }

  // Notebook Files component dark mode styles
  .manage-files-wrapper {
    .upload-zone {
      ::ng-deep .file-upload {
        border-color: rgba($light-mode, 0.2);
        background-color: rgba($dark-mode-lighten, 0.15);

        &:hover,
        &.has-files {
          border-color: rgba($light-mode, 0.4);
          background-color: rgba($dark-mode-lighten, 0.25);
        }
      }

      .upload-zone-content {
        color: rgba($light-mode, 0.8);

        mat-icon {
          color: rgba($light-mode, 0.6);
        }

        p {
          color: rgba($light-mode, 0.8);
        }

        small {
          color: rgba($light-mode, 0.6);
        }
      }

      .file-info {
        background-color: rgba($dark-mode-lighten, 0.15);

        &:hover {
          background-color: rgba($dark-mode-lighten, 0.25);
        }

        .file-name {
          color: rgba($light-mode, 0.8);
        }
      }
    }

    .upload-progress {
      background-color: $dark-mode-lighten;

      .progress-status {
        .progress-text {
          color: $light-mode;
        }
      }

      .upload-results {
        .success-message {
          background-color: rgba(#98fb98, 0.15); // Light green background
          color: $light-mode;

          mat-icon {
            color: #98fb98;
          }
        }

        .error-message {
          background-color: rgba(#ff6b6b, 0.15); // Light red background
          color: $light-mode;

          mat-icon {
            color: #ff6b6b;
          }
        }
      }
    }
  }
}
