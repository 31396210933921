// Tables
table {
    width: 100%;
}

.table-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 300px;
}

.table-container {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    overflow: auto;
    height: 80vh;
}

.table > thead {
    vertical-align: middle;
  }
  .table > tbody {
    vertical-align: middle;
  }
  .table > :not(:first-child) {
    border-top: 0;
  }